<template>
  <v-container>
    <v-data-table
      :headers="headers[$vuetify.breakpoint.smAndDown]"
      :items="items"
      :items-per-page="7"
      item-key="code"
      sort-by="name"
      mobile-breakpoint="0"
      class="table-cursor tbl pt-12"
      :search="search"
      loading-text="Cargando ..."
      group-by="agent"
      show-group-by
    >
      <template v-slot:[`item.picture`]="{ item }" class="pa-0 ma-0">
        <v-avatar>
          <v-img
            :src="getImagePhoto(item.account, item.code, item.picture)"
          ></v-img>
        </v-avatar>
      </template>
      <template v-slot:[`item.name`]="{ item }" class="pa-0 ma-0">
        <b>{{ item.name }}</b>
        <small color="success">{{ item.status }}</small>
        <br />
        <span v-if="$vuetify.breakpoint.smAndDown">
          <small>({{ item.group_name }})</small>
          <br />
          <small>{{ item.address }} {{ item.mobile }}</small>
          <br />
          <small>@ {{ item.issue_date }}</small>

          <span v-if="item.location">
            <v-icon color="success" small>
              mdi-map-marker-check-outline
            </v-icon>
          </span>
        </span>
      </template>
      <template v-slot:[`item.action`]="{ item }" class="pa-0 ma-0">
        <v-btn
          icon
          @click="handleClick(item)"
          v-if="$vuetify.breakpoint.smAndDown"
        >
          <v-icon>mdi-square-edit-outline</v-icon>
        </v-btn>
      </template>

      <template v-slot:[`item.location`]="{ item }" class="pa-0 ma-0">
        <span v-if="item.location">
          <v-icon color="success" small>mdi-map-marker-check-outline</v-icon>
        </span>
      </template>

      <template v-slot:[`item.issue_date`]="{ item }" class="pa-0 ma-0">
        <span v-if="$vuetify.breakpoint.smAndDown"></span>
        <span v-else>
          {{ item.issue_date }}
        </span>
      </template>
      <template v-slot:[`item.qr`]="{ item }" class="pa-0 ma-0">
        <v-btn v-if="item.qr" text @click="openURL(item.qr)">
          <v-icon>mdi-qrcode-scan</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.action`]="{ item }" class="pa-0 ma-0">
        <v-btn text @click="handleClick(item)">
          <v-icon>mdi-square-edit-outline</v-icon>
        </v-btn>
      </template>
    </v-data-table>

    <v-btn color="orange" class="float" fab x-small dark @click="newForm()">
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <StoreParty
      :table="table"
      :item="item"
      :dialog="dialog"
      :newItem="newItem"
      :groups="groups"
      :subgroups="subgroups"
      @close="dialog = false"
      @refresh="refresh()"
    />

    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando ...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-bottom-navigation dark app>
      <v-toolbar>
        <v-row justify="center">
          <v-col md="6" cols="12">
            <v-text-field
              v-model="search"
              class="srch"
              hide-details
              append-icon="mdi-magnify"
              append-outer-icon="mdi-map-marker-radius-outline"
              dense
              outlined
              rounded
              @click:append="searchRestaurants"
              @click:append-outer="maps"
            />
          </v-col>
        </v-row>
      </v-toolbar>
    </v-bottom-navigation>
  </v-container>
</template>

<script>
import { webserver } from '../services/webserver.js'
import createItem from '../utils/createStore.js'
import StoreParty from '../components/store_Form.vue'
export default {
  components: { StoreParty },
  mounted() {
    this.get_employees()
  },
  data() {
    return {
      table: 'stores',
      dialog: false,
      item_dialog: false,
      newItem: false,
      search: '',
      loading_status: false,
      errors: [],
      items: [],
      employees: [],
      items_bygroup: [],
      items_bysubgroup: [],
      item: createItem(),
      headers: {
        false: [
          {
            text: 'Grupo',
            align: 'start',
            sortable: true,
            value: 'group_name',
            dataType: 'text',
          },
          {
            text: 'Nombre',
            align: 'start',
            sortable: true,
            value: 'name',
            dataType: 'text',
          },
          {
            text: 'Dirección',
            align: 'start',
            sortable: true,
            value: 'address',
            dataType: 'text',
          },
          {
            text: 'Tel.',
            align: 'end',
            value: 'mobile',
            dataType: 'text',
            width: 150,
          },
          {
            text: 'QR',
            align: 'end',
            value: 'qr',
            dataType: 'text',
            width: 150,
          },
          {
            text: 'Status',
            align: 'end',
            value: 'status',
            dataType: 'text',
            width: 150,
          },
          {
            text: 'GEO',
            value: 'location',
            width: 10,
          },
          {
            text: 'Fecha',
            align: 'end',
            value: 'issue_date',
            dataType: 'text',
            width: 200,
          },
          {
            text: 'Promoto',
            align: 'end',
            value: 'agent',
            dataType: 'text',
          },
          {
            text: '',
            align: 'end',
            value: 'action',
            dataType: 'text',
          },
        ],
        true: [
          {
            text: '',
            align: 'start',
            sortable: true,
            value: 'picture',
            dataType: 'text',
            width: 40,
          },
          {
            text: 'Nombre',
            align: 'start',
            sortable: true,
            value: 'name',
            dataType: 'text',
          },

          {
            text: '',
            align: 'end',
            value: 'action',
            dataType: 'text',
            width: 10,
          },
        ],
      },
      groups: [],
      subgroups: [],
    }
  },
  methods: {
    searchRestaurants() {
      var field = document.createElement('input')
      field.setAttribute('type', 'text')
      document.body.appendChild(field)

      setTimeout(function () {
        field.focus()
        setTimeout(function () {
          field.setAttribute('style', 'display:none;')
        }, 50)
      }, 50)
    },
    maps() {
      this.$router.push('/StoresMap')
    },
    edit_menu(e) {
      window.store = { ...e }
      this.$router.push('/QR4')
    },
    handleClick(data) {
      this.item = { ...data }
      this.dialog = true
      //this.newItem = false;
      //this.dialog = true;
    },
    editItem(e) {
      this.item = { ...e }
      this.newItem = false
      this.dialog = true
    },
    updateLocation(e) {
      this.store.location = JSON.stringify(e)
    },
    get_items() {
      this.items_bygroup = null
      var qry = {
        account: this.$store.state.profile.account,
        table: this.table,
        filters: [
          {
            field: 'account',
            operator: '=',
            value: this.$store.state.profile.account,
          },
        ],
      }
      // if (
      //   this.$store.state.profile.user_access === 'Sales' ||
      //   this.$store.state.profile.user_access === 'Promotor'
      // ) {
      //   qry.filters.push({
      //     field: 'agent',
      //     operator: '=',
      //     value: this.$store.state.profile.name,
      //   })
      // }
      console.log(qry)
      this.loading_status = true
      webserver('get_table', qry, (data) => {
        this.loading_status = false

        this.items_bygroup = data.reduce(function (r, a) {
          r[a.group_name] = r[a.group_name] || []
          r[a.group_name].push(a)
          return r
        }, Object.create(null))

        this.groups = Object.keys(this.items_bygroup).sort()

        this.items_bysubgroup = data.reduce(function (r, a) {
          r[a.subgroup_name] = r[a.subgroup_name] || []
          r[a.subgroup_name].push(a)
          return r
        }, Object.create(null))

        this.subgroups = Object.keys(this.items_bysubgroup).sort()

        data.forEach((rg) => {
          var result = this.employees.filter((empl) => empl.name === rg.agent)
          if (result[0]) {
            rg.agent_pic = result[0].code + '/' + result[0].picture
          }
        })

        this.items = data
        if (data.length > 0) {
          this.items = data
        } else {
          this.newForm()
        }
      })
    },
    refresh() {
      this.dialog = false
      this.get_items()
    },
    getImagePhoto: function (account, code, src) {
      var url =
        'https://smartchef.pro/photos/' + account + '/' + code + '/' + src
      return url
    },
    getImageMenu: function (account, code, src) {
      var url =
        'https://smartchef.pro/menus/' + account + '/' + code + '/' + src
      if (this.compressed_image) url = this.compressed_image
      return url
    },
    getImageAgent: function (account, src) {
      var url = 'https://smartchef.pro/photos/' + account + '/' + src
      return url
    },
    newForm() {
      this.item = createItem()
      this.item.account = this.$store.state.profile.account
      this.valid = false
      this.newItem = true
      this.dialog = true
    },
    call_contact(data) {
      window.location.href = 'tel://' + data
    },
    openURL(e) {
      window.open(e, '', 'width=400,height=800')
    },
    get_employees() {
      var qry = {
        account: this.$store.state.profile.account,
        table: 'employees',
        filters: [],
      }
      webserver('get_table', qry, (data) => {
        this.employees = data
        this.get_items()
      })
    },
  },
  watch: {},
}
</script>

<style lang="scss">
.action_bottom {
  position: absolute;
  bottom: 0;
}
.float {
  position: fixed;
  width: 40px;
  height: 40px;
  top: 64px;
  right: 20px;
  background-color: #0c9;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}
.tbl {
  background: transparent;
}
.text-start {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
</style>

export default (data = {}) => {
  return {
    "Mexico": {
      "Ciudad de México": [
        "Mexico City"
      ],
      "Jalisco": [
        "Guadalajara",
        "Zapopan",
        "Tlajomulco de Zúñiga",
        "Tlaquepaque",
        "Tonalá",
        "Puerto Vallarta",
        "Lagos de Moreno",
        "Tepatitlán de Morelos",
        "Tala",
        "La Barca",
        "Tomatlán",
        "Jamay",
        "Chapala",
        "Degollado",
        "San Patricio",
        "Ciudad Guzmán",
        "San Juan de los Lagos",
        "Zapotlanejo",
        "Autlán de Navarro",
        "Encarnación de Díaz",
        "Jocotepec",
        "Nuevo México",
        "Tequila",
        "Sayula",
        "Cihuatlán",
        "Zacoalco de Torres",
        "San Martín Hidalgo",
        "La Huerta",
        "Ahualulco de Mercado",
        "Tototlán",
        "Magdalena",
        "Yahualica de González Gallo",
        "Acatlán",
        "La Resolana",
        "Acatic",
        "Villa Hidalgo",
        "El Arenal",
        "Tapalpa",
        "Ixtlahuacán del Río",
        "Cuquío",
        "Cuautitlán",
        "Zapotlán del Rey",
        "Unión de San Antonio",
        "San Julián",
        "Amatitán",
        "Ojuelos de Jalisco",
        "San Juanito de Escobedo",
        "Villa Purificación",
        "Teocuitatlán de Corona",
        "Jilotlán de los Dolores",
        "Hostotipaquillo",
        "Valle de Guadalupe",
        "Tonila",
        "San Diego de Alejandría",
        "Tenamaxtlán",
        "Tuxcueca",
        "Atengo",
        "Chiquilistlán",
        "Concepción de Buenos Aires",
        "San Sebastián del Oeste",
        "Huejúcar",
        "Valle de Juárez",
        "El Limón",
        "San Francisco"
      ],
      "Nuevo León": [
        "Monterrey",
        "Guadalupe",
        "Ciudad Apodaca",
        "San Nicolás de los Garza",
        "Ciudad General Escobedo",
        "Ciudad Benito Juárez",
        "Ciudad Santa Catarina",
        "García",
        "San Pedro Garza García",
        "Cadereyta Jiménez",
        "Linares",
        "Ciudad Sabinas Hidalgo",
        "Valle Alto",
        "Montemorelos",
        "Galeana",
        "Santiago",
        "Salinas Victoria",
        "Ciénega de Flores",
        "Ciudad Cerralvo",
        "General Bravo",
        "Lampazos de Naranjo"
      ],
      "Baja California": [
        "Tijuana",
        "Mexicali",
        "Ensenada",
        "Tecate",
        "Santa Isabel",
        "Rodolfo Sánchez Taboada",
        "Guerrero Negro",
        "Vicente Guerrero",
        "Algodones",
        "Playas de Rosarito",
        "San Felipe",
        "Progreso",
        "Colonia Venustiano Carranza",
        "Punta Prieta",
        "San Quintín"
      ],
      "México": [
        "Ecatepec",
        "Ciudad Nezahualcóyotl",
        "Nezahualcóyotl",
        "Naucalpan de Juárez",
        "Toluca",
        "Tlalnepantla",
        "Chimalhuacán",
        "Cuautitlán Izcalli",
        "Ixtapaluca",
        "Ciudad López Mateos",
        "Tultitlán de Mariano Escobedo",
        "Nicolás Romero",
        "Tecámac",
        "Chalco",
        "Coacalco",
        "Texcoco",
        "Buenavista",
        "San Pablo de las Salinas",
        "Chicoloapan",
        "Zinacantepec",
        "Ixtlahuaca",
        "Cuautitlán",
        "San Antonio Enchisi",
        "Lerma",
        "San Felipe del Progreso",
        "Jilotepec",
        "Tenancingo",
        "Tultepec",
        "Fuentes del Valle",
        "Tejupilco",
        "Jacona de Plancarte",
        "Jiquílpan de Juárez",
        "Valle de Bravo",
        "Teoloyucan",
        "Temascalcingo",
        "San Buenaventura",
        "Zumpango",
        "Santiago Teyahualco",
        "Tlalmanalco",
        "Amecameca de Juárez",
        "Ixtapan de la Sal",
        "Otumba",
        "Malinalco",
        "Chiconcuac",
        "Cotija de la Paz",
        "Mexicaltzingo",
        "La Magdalena Chichicaspa",
        "Rayón",
        "Ayapango",
        "Villa Victoria",
        "Tepotzotlán",
        "Santiago Tianguistenco",
        "Huehuetoca",
        "Acambay",
        "Jocotitlán",
        "San José Villa de Allende",
        "Calimaya",
        "Atenco",
        "Villa del Carbón",
        "Melchor Ocampo",
        "Coatepec Harinas",
        "Capulhuac",
        "Temascaltepec de González",
        "Villa Donato Guerra",
        "Villa Luvianos",
        "Tlalpujahua de Rayón",
        "Atlautla",
        "Ozumba",
        "Xalatlaco",
        "Amatepec",
        "Jaltenco",
        "Ocuilan de Arteaga",
        "Tepetlaoxtoc",
        "Sultepec",
        "Juchitepec",
        "Santa Ana Nextlalpan",
        "Chapa de Mota",
        "Axapusco",
        "San Martín de las Pirámides",
        "Santa Ana Jilotzingo",
        "Tepetlixpa",
        "San Andrés Timilpan",
        "Almoloya de Alquisiras",
        "Polotitlán de la Ilustración",
        "Zacualpan",
        "Cocotitlán",
        "Temamatla",
        "Joquicingo",
        "Tenango del Aire",
        "Soyaniquilpan",
        "Santa Catarina Ayotzingo",
        "Santa Cruz Atizapán",
        "Huaniqueo de Morales",
        "Ecatzingo",
        "Villa Morelos",
        "Santo Tomás de los Plátanos",
        "Ixtapan del Oro",
        "San Simón de Guerrero"
      ],
      "Puebla": [
        "Puebla",
        "Tehuacán",
        "Cholula de Rivadabia",
        "San Martin Texmelucan de Labastida",
        "Santa María Texmelucan",
        "Ciudad de Atlixco",
        "Huauchinango",
        "Zacatlán",
        "Amozoc",
        "Acatzingo",
        "Cuetzalan",
        "Izúcar de Matamoros",
        "San Salvador El Seco",
        "Los Reyes de Juárez",
        "Miahuatlán",
        "Altepexi",
        "Zaragoza",
        "Tepatlaxco",
        "Acatlán de Osorio",
        "Grajales",
        "Huixcolotla",
        "Huitzilan",
        "San Nicolás de los Ranchos",
        "San Miguel Xoxtla",
        "Chiautla de Tapia",
        "Cuapiaxtla de Madero",
        "San Gregorio Atzompa",
        "Zacapoaxtla",
        "San Juan Ixcaquixtla",
        "Tepeojuma",
        "Ayotoxco de Guerrero",
        "Xochitlan Todos Santos",
        "Teziutlan",
        "Santa Catarina Otzolotepec",
        "Huaquechula",
        "Huejotzingo",
        "San Miguel Ocotenco",
        "Ciudad Serdán",
        "Tecali",
        "Tepexi de Rodríguez",
        "Huehuetla",
        "Guadalupe Victoria",
        "Eloxochitlán",
        "Olintla",
        "Tepetzintla",
        "Teotlaltzingo",
        "San Nicolas Buenos Aires",
        "Santa Isabel Cholula",
        "Tecuanipan",
        "Teteles de Avila Castillo",
        "Zapotitlán",
        "Tuzamapan",
        "San Antonio Cañada"
      ],
      "Guanajuato": [
        "León de los Aldama",
        "Celaya",
        "Irapuato",
        "Salamanca",
        "Guanajuato",
        "Silao",
        "Dolores Hidalgo Cuna de la Independencia Nacional",
        "Valle de Santiago",
        "San Miguel de Allende",
        "San Francisco del Rincón",
        "San Felipe",
        "San Luis de la Paz",
        "Acámbaro",
        "Cortazar",
        "Romita",
        "San José Iturbide",
        "Villagrán",
        "Moroleón",
        "San Diego de la Unión",
        "Salvatierra",
        "Abasolo",
        "Ocampo",
        "Purísima de Bustos",
        "Comonfort",
        "Juventino Rosas",
        "Apaseo el Alto",
        "Uriangato",
        "Pénjamo",
        "Ciudad Manuel Doblado",
        "Jaral del Progreso",
        "Cuerámaro",
        "Doctor Mora",
        "Huanímaro",
        "Victoria",
        "Tierra Blanca",
        "Xichú",
        "Coroneo",
        "Santiago Maravatío",
        "La Moncada",
        "Santa Catarina",
        "Atarjea"
      ],
      "Chihuahua": [
        "Juárez",
        "Chihuahua",
        "Cuauhtémoc",
        "Delicias",
        "Parral",
        "Las Delicias",
        "Nuevo Casas Grandes",
        "Jiménez",
        "Ojinaga",
        "Aldama",
        "Meoqui",
        "Madera",
        "Guachochi",
        "Ascensión",
        "Saucillo",
        "San Juanito",
        "Anáhuac",
        "La Junta",
        "Ahumada",
        "Guadalupe y Calvo",
        "Guacharachi",
        "Camargo",
        "Lázaro Cárdenas",
        "Guerrero",
        "Santa Bárbara",
        "Santa Eulalia",
        "Rosales",
        "San Buenaventura",
        "Creel",
        "Gómez Farías"
      ],
      "Aguascalientes": [
        "Aguascalientes",
        "Rincón de Romos",
        "Ojo Caliente",
        "Palo Alto",
        "San Francisco de los Romo",
        "Jesús María",
        "Pabellón de Arteaga",
        "Tepezalá",
        "Calvillo",
        "San José de Gracia"
      ],
      "Yucatán": [
        "Mérida",
        "Kanasín",
        "Valladolid",
        "Tizimín",
        "Uman",
        "Ticul",
        "Hunucmá",
        "Oxkutzkab",
        "Motul",
        "Peto",
        "Chemax",
        "Izamal",
        "Muna",
        "Akil",
        "Tixkokob",
        "Acanceh",
        "Tzucacab",
        "Tekit",
        "Halachó",
        "Buctzotz",
        "Celestún",
        "Temozon",
        "Teabo",
        "Piste",
        "Panabá",
        "X-Cán",
        "Chicxulub Puerto",
        "Tecax",
        "Progreso",
        "Maxcanú",
        "Espita",
        "Tecoh",
        "Seyé",
        "Conkal",
        "Cacalchen",
        "Dzidzantun",
        "Kinchil",
        "Temax",
        "Dzilam González",
        "Homun",
        "Chichimila",
        "Sotuta",
        "Tixpehual",
        "Samahil",
        "Tamchen"
      ],
      "San Luis Potosí": [
        "San Luis Potosí",
        "Soledad de Graciano Sánchez",
        "Ciudad Valles",
        "Tamazunchale",
        "Rioverde",
        "Matehuala",
        "Cárdenas",
        "Cerritos",
        "Tamuín",
        "San Nicolás Tolentino"
      ],
      "Querétaro": [
        "Querétaro",
        "San Juan del Río",
        "Ezequiel Montes",
        "Santa Rosa Jauregui",
        "Amealco",
        "Tequisquiapan",
        "Pinal de Amoles",
        "Jalpan",
        "Landa de Matamoros",
        "Peñamiller",
        "Cadereyta",
        "Arroyo Seco",
        "San Joaquín"
      ],
      "Sonora": [
        "Hermosillo",
        "Mazatán",
        "Ciudad Obregón",
        "Heroica Nogales",
        "San Luis Río Colorado",
        "Navojoa",
        "Heroica Guaymas",
        "Etchojoa",
        "Empalme",
        "Huatabampo",
        "Cananea",
        "Magdalena de Kino",
        "Sonoita",
        "Santa Ana",
        "San Ignacio Cohuirimpo",
        "Cocorit",
        "Bahía Kino",
        "Altar",
        "Esperanza",
        "Agua Prieta",
        "Puerto Peñasco",
        "Heroica Caborca",
        "Álamos",
        "Nacozari Viejo",
        "Imuris",
        "Sahuaripa",
        "Naco",
        "Benjamín Hill",
        "Vícam Pueblo"
      ],
      "Coahuila de Zaragoza": [
        "Saltillo",
        "Torreón",
        "Monclova",
        "Ciudad Acuña",
        "Piedras Negras",
        "Frontera",
        "Sabinas",
        "San Pedro",
        "Matamoros",
        "Francisco I. Madero",
        "Ramos Arizpe",
        "Parras de la Fuente",
        "Nueva Rosita",
        "Castaños",
        "Palau",
        "Cuatro Ciénegas de Carranza",
        "San Juan de Sabinas",
        "Nava",
        "Viesca",
        "Allende",
        "Arteaga"
      ],
      "Michoacán de Ocampo": [
        "Morelia",
        "Uruapan",
        "Zamora",
        "Apatzingan de la Constitucion",
        "Ciudad Hidalgo",
        "La Piedad",
        "Ciudad Lázaro Cárdenas",
        "Tacámbaro de Codallos",
        "Paracho de Verduzco",
        "Nueva Italia de Ruiz",
        "Tancítaro",
        "Cuitzeo del Porvenir",
        "Tuxpan",
        "Venustiano Carranza",
        "Paracuaro",
        "Cherán",
        "Puréparo de Echaíz",
        "Tingüindín",
        "Coalcomán de Vázquez Pallares",
        "Pajacuarán",
        "La Huacana",
        "Panindícuaro",
        "Pátzcuaro",
        "Sahuayo de Morelos",
        "Tepalcatepec",
        "Turicato",
        "Aquila",
        "Senguio",
        "Ziracuaretiro",
        "Churumuco de Morelos",
        "Tocumbo",
        "Santa Ana Maya",
        "Tzintzuntzán",
        "Huandacareo",
        "Acuítzio del Canje",
        "Mineral de Angangueo",
        "Chavinda",
        "Tzitzio",
        "Carácuaro",
        "Tumbiscatío de Ruiz"
      ],
      "Sinaloa": [
        "Culiacán",
        "Mazatlán",
        "Los Mochis",
        "Guamúchil",
        "Guasave",
        "Escuinapa",
        "Navolato",
        "El Rosario",
        "El Dorado",
        "La Cruz",
        "Villa Unión",
        "El Fuerte",
        "Choix",
        "Higuera de Zaragoza",
        "Concordia",
        "Angostura",
        "Guasavito",
        "Cosalá",
        "Topolobampo",
        "Altata"
      ],
      "Quintana Roo": [
        "Cancún",
        "Playa del Carmen",
        "Chetumal",
        "Cozumel",
        "Tulum",
        "Isla Mujeres",
        "Bacalar",
        "Kantunilkín",
        "Leona Vicario",
        "Calderitas",
        "Felipe Carrillo Puerto",
        "José María Morelos",
        "Puerto Morelos",
        "Puerto Aventuras"
      ],
      "Tamaulipas": [
        "Reynosa",
        "Heroica Matamoros",
        "Nuevo Laredo",
        "Tampico",
        "Ciudad Victoria",
        "Ciudad Madero",
        "Miramar",
        "Ciudad Mante",
        "Ciudad Río Bravo",
        "Valle Hermoso",
        "Altamira",
        "San Fernando",
        "Ciudad Miguel Alemán",
        "Aldama",
        "Nuevo Progreso",
        "González",
        "Ciudad Tula",
        "Xicoténcatl",
        "Abasolo",
        "Ocampo",
        "General Zaragoza"
      ],
      "Guerrero": [
        "Acapulco de Juárez",
        "Chilpancingo",
        "Chilapa de Álvarez",
        "Iguala de la Independencia",
        "Zihuatanejo",
        "Tlapa de Comonfort",
        "Ometepec",
        "Zumpango del Río",
        "Tixtla de Guerrero",
        "Ciudad Altamirano",
        "Atoyac de Álvarez",
        "Ayutla de los Libres",
        "Técpan de Galeana",
        "Ciudad de Huitzuco",
        "Tierra Colorada",
        "Chichihualco",
        "San Luis de La Loma",
        "Taxco de Alarcón",
        "Petatlán",
        "Xochistlahuaca",
        "Cuajinicuilapa",
        "Cruz Grande",
        "Coycoyan de las Flores",
        "Ixcateopan de Cuauhtémoc",
        "Ixtapa Zihuatanejo"
      ],
      "Durango": [
        "Durango",
        "Gómez Palacio",
        "Ciudad Lerdo",
        "El Salto",
        "Ciudad Guadalupe Victoria",
        "Vicente Guerrero",
        "Cuencamé de Ceniceros",
        "Villa Unión",
        "Canatlán",
        "Nuevo Ideal",
        "Tlahualilo de Zaragoza",
        "Tayoltita",
        "Nombre de Dios",
        "Pedro Celestino Negrete",
        "Santiago Papasquiaro",
        "San Miguel de Papasquiaro",
        "Colonia General Felipe Ángeles",
        "Santa María del Oro",
        "Mapimí",
        "Peñón Blanco"
      ],
      "Tabasco": [
        "Villahermosa",
        "Jalpa de Méndez",
        "Nacajuca",
        "Cárdenas",
        "Cunduacán",
        "Macuspana",
        "Tenosique",
        "Frontera",
        "Balancán",
        "Tacotalpa",
        "Paraíso"
      ],
      "Chiapas": [
        "Tuxtla",
        "Tapachula",
        "Comitán",
        "San Cristóbal",
        "Escuintla",
        "Chiapa de Corzo",
        "Ocosingo",
        "Tonalá",
        "Zinacantán",
        "Ciudad Hidalgo",
        "Jaltenango",
        "Altamirano",
        "Frontera Corozal",
        "Pijijiapan",
        "Berriozábal",
        "Mapastepec",
        "Simojovel de Allende",
        "Yajalón",
        "Siltepec",
        "Bochil",
        "Tuzantán",
        "Amatenango de la Frontera",
        "Las Rosas",
        "Sabanilla",
        "Amatán",
        "Chalchihuitán",
        "El Porvenir de Velasco Suárez",
        "Acala",
        "Tapilula",
        "Maravilla Tenejapa",
        "Amatenango del Valle",
        "Solosuchiapa",
        "Mazapa",
        "Montecristo",
        "Chapultenango",
        "Francisco León",
        "La Grandeza",
        "Ixtapangajoya"
      ],
      "Veracruz": [
        "Veracruz",
        "Xalapa",
        "Minatitlán",
        "Coatzacoalcos",
        "Córdoba",
        "Poza Rica de Hidalgo",
        "Papantla de Olarte",
        "Túxpam de Rodríguez Cano",
        "Boca del Rio",
        "Orizaba",
        "Martínez de la Torre",
        "Coatepec",
        "Acayucan",
        "Misantla",
        "Huatusco",
        "Cosamaloapan",
        "Tezonapa",
        "Jáltipan de Morelos",
        "Playa Vicente",
        "Perote",
        "Juan Rodríguez Clara",
        "Xico",
        "Sayula de Alemán",
        "Mariano Escobedo",
        "Pánuco",
        "Nanchital de Lázaro Cárdenas del Río",
        "Soteapan",
        "Paso de Ovejas",
        "Paso del Macho",
        "San Juan Evangelista",
        "Catemaco",
        "Soledad de Doblado",
        "Álamo",
        "Nogales",
        "Ixhuatlancillo",
        "Rafael Delgado",
        "Tantoyuca",
        "Cerro Azul",
        "Carlos A. Carrillo",
        "Cazones de Herrera",
        "Texistepec",
        "Chocamán",
        "Hidalgotitlán",
        "Mecayapan",
        "Fortín de las Flores",
        "Tatahuicapan",
        "Pajapan",
        "Tlaltetela",
        "Juchique de Ferrer",
        "Oteapan",
        "Tempoal de Sánchez",
        "Ángel R. Cabada",
        "Coacoatzintla",
        "Platón Sánchez",
        "Tlacotalpan",
        "Amatlán de los Reyes",
        "Coahuitlán",
        "Rafael Lucio",
        "El Higo",
        "Huiloapan",
        "Ciudad Cuauhtémoc",
        "Santiago Tuxtla",
        "Ixhuatlán de Madero",
        "Actopan",
        "Tres Valles",
        "Zongolica",
        "Hueyapan de Ocampo",
        "Tlalixcoyan",
        "San Rafael",
        "Tehuipango",
        "Tecolutla",
        "Tlacolula",
        "Soledad Atzompa",
        "Tamiahua",
        "Puente Nacional",
        "Alvarado",
        "Naranjos",
        "Comapa",
        "Lerdo de Tejada",
        "Tlalnelhuayocan",
        "Filomeno Mata",
        "Moloacán",
        "Totutla",
        "Cosautlán",
        "Ignacio de la Llave",
        "Jilotepec",
        "Chinampa de Gorostiza",
        "Chiconquiaco",
        "Ixhuatlán del Sureste",
        "Tepetzintla",
        "Zontecomatlán de López y Fuentes",
        "Ixcatepec",
        "Ilamatlán",
        "Mecatlán",
        "Cuichapa",
        "Mixtla de Altamirano",
        "Citlaltépec",
        "Tlacolulan",
        "Villa Aldama",
        "Jamapa",
        "Tepetlán",
        "Texcatepec",
        "Tlachichilco",
        "Tenampa",
        "Colipa",
        "Tomatlán",
        "Zacualpan",
        "Santiago",
        "Tlilapan",
        "Texhuacán",
        "Tatatila",
        "Los Reyes",
        "Tancoco",
        "Xoxocotla",
        "Acula",
        "Jalcomulco",
        "Nautla"
      ],
      "Morelos": [
        "Cuernavaca",
        "Metepec",
        "Cuautla",
        "Jiutepec",
        "Temixco",
        "Tizayuca",
        "Emiliano Zapata",
        "Coyotepec",
        "Xoxocotla",
        "Atlatlahucan",
        "San Juan Zitlaltepec",
        "Tequixquiac",
        "Reyes Acozac",
        "Tepoztlán",
        "Anenecuilco",
        "Tetela del Volcán",
        "Guadalupe Yancuictlalpan",
        "Temoaya",
        "Ocoyoacac",
        "Yecapixtla",
        "Zacatepec",
        "El Oro de Hidalgo",
        "Tlayacapan",
        "Tolcayuca",
        "Totolapan",
        "Almoloya del Río",
        "Santa María Ajoloapan",
        "Nopaltepec",
        "Zacualpan de Amilpas",
        "Tlalnepantla",
        "Tlapanaloya",
        "Tetecala",
        "Coatetelco"
      ],
      "Nayarit": [
        "Tepic",
        "Tuxpan",
        "Xalisco",
        "Santiago Ixcuintla",
        "Tecuala",
        "Ahuacatlán",
        "San Blas",
        "Compostela"
      ],
      "Oaxaca": [
        "Oaxaca",
        "Tuxtepec",
        "Salina Cruz",
        "Juchitán de Zaragoza",
        "Puerto Escondido",
        "Villa de Zaachila",
        "Miahuatlán de Porfirio Díaz",
        "Santa María Atzompa",
        "Putla Villa de Guerrero",
        "Heroica Ciudad de Tlaxiaco",
        "Tlacolula de Matamoros",
        "Zimatlán de Álvarez",
        "Santa Catarina Juquila",
        "La Crucecita",
        "Santa Cruz Amilpas",
        "Santa María Jalapa del Marqués",
        "Santa María Huazolotitlán",
        "San Pedro Jicayán",
        "San Pedro Ixcatlán",
        "Tlalixtac de Cabrera",
        "Tataltepec de Valdés",
        "San Francisco del Mar",
        "Tamazulapam Villa del Progreso",
        "Temascal",
        "San Pedro Amuzgos",
        "San Antonino Castillo Velasco",
        "Soledad Etla",
        "Santa Cruz Xoxocotlán",
        "Ciudad de Huajuapam de León",
        "Santo Domingo Tehuantepec",
        "Santa Lucía del Camino",
        "San Pedro Mixtepec",
        "Matías Romero",
        "San Felipe Jalapa de Díaz",
        "San Juan Guichicovi",
        "Santa María Tonameca",
        "San Agustín Loxicha",
        "San Juan Cotzocón",
        "Cuilapan de Guerrero",
        "Ocotlán de Morelos",
        "Santa María Chilchotla",
        "Jamiltepec",
        "Santa Cruz Zenzontepec",
        "Asunción Nochixtlán",
        "San Blas Atempa",
        "San José Tenango",
        "Santos Reyes Nopala",
        "San Juan Lalana",
        "San Jacinto Amilpas",
        "Santa María Petapa",
        "San Pedro Tapanatepec",
        "El Barrio de la Soledad",
        "Santiago Ixtayutla",
        "San Pedro Pochutla",
        "Mazatlán Villa de Flores",
        "Santiago Amoltepec",
        "Tezoatlán de Segura y Luna",
        "San Carlos Yautepec",
        "Santa Cruz Itundujia",
        "San Pablo Huixtepec",
        "Santiago Suchilquitongo",
        "San Agustín de las Juntas",
        "San Pedro Huamelula",
        "Cuicatlan",
        "San Pedro Ixtlahuaca",
        "Santa María del Tule",
        "Santo Domingo Petapa",
        "Villa Chalcatongo de Hidalgo",
        "San Francisco Ixhuatan",
        "San Pablo Villa de Mitla",
        "Santa María Xadani",
        "El Espinal",
        "San Pablo Huitzo",
        "San Miguel del Puerto",
        "San Sebastián Tecomaxtlahuaca",
        "Santiago Yosondúa",
        "Texmelucan",
        "Albarradas",
        "San Miguel Quetzaltepec",
        "San Felipe Tejalapan",
        "Villa Díaz Ordaz",
        "San Antonino Monteverde",
        "Huautepec",
        "Ayotzintepec",
        "Pinotepa de Don Luis",
        "San José del Progreso",
        "Santiago Yaveo",
        "San Juan Mixtepec",
        "Santa Lucía Monte Verde",
        "Silacayoápam",
        "Santa María Yucuhiti",
        "Magdalena Tequisistlán",
        "San Miguel Panixtlahuaca",
        "San Juan Ñumí",
        "San Andrés Huayápam",
        "Teojomulco",
        "Totontepec Villa de Morelos",
        "Santiago Zacatepec",
        "San Cristóbal Amatlán",
        "San Dionisio del Mar",
        "San Jerónimo Coatlán"
      ],
      "Hidalgo": [
        "Pachuca",
        "Tulancingo",
        "Huejutla de Reyes",
        "Tula de Allende",
        "Actopan",
        "Calpulalpan",
        "Ciudad Sahagun",
        "Santiago Tulantepec",
        "Apaxco de Ocampo",
        "Tepeji del Río de Ocampo",
        "Ixmiquilpan",
        "Tepeapulco",
        "Cuautepec de Hinojosa",
        "Apan",
        "San Felipe Orizatlán",
        "Mixquiahuala de Juarez",
        "Acaxochitlán",
        "Zimapan",
        "Temascalapa",
        "Tecozautla",
        "Atotonilco de Tula",
        "San Salvador",
        "Zempoala",
        "Atitalaquia",
        "Tepehuacán de Guerrero",
        "San Agustín Tlaxiaca",
        "Tlaxcoapan",
        "Zacualtipán",
        "Huautla",
        "Yahualica",
        "Chachahuantla",
        "Chapulhuacán",
        "Metztitlán",
        "Atlapexco",
        "Acatlán",
        "Xochiatipan de Castillo",
        "San Bartolo Tutotepec",
        "Tlahuelilpan",
        "Pisaflores",
        "Calnali",
        "Ajacuba",
        "Alfajayucan",
        "Zapotlán de Juárez",
        "Cardonal",
        "Tenango de Doria",
        "Tasquillo",
        "Chilcuautla",
        "Huasca de Ocampo",
        "El Arenal",
        "Nopala de Villagran",
        "Santiago de Anaya",
        "Tianguistengo",
        "Singuilucan",
        "Emiliano Zapata",
        "Mineral del Monte",
        "Jacala",
        "Epazoyucan",
        "Chapantongo",
        "Tezontepec",
        "Almoloya",
        "Jaltocan",
        "Molango",
        "La Misión",
        "Lototla",
        "Tlahuiltepa",
        "Tepetitlan",
        "Tlanalapa",
        "Agua Blanca Iturbide",
        "Mineral del Chico",
        "Omitlán de Juárez",
        "Xochicoatlán",
        "Nicolás Flores"
      ],
      "Baja California Sur": [
        "La Paz",
        "Cabo San Lucas",
        "Colonia del Sol",
        "Loreto",
        "Santa Rosalía",
        "Las Veredas",
        "San José el Viejo",
        "Puerto San Carlos",
        "San José del Cabo",
        "Ciudad Constitución",
        "Ciudad Insurgentes",
        "Todos Santos",
        "El Centenario"
      ],
      "Zacatecas": [
        "Guadalupe",
        "Zacatecas",
        "Fresnillo",
        "Sombrerete",
        "Loreto",
        "Villanueva",
        "Valparaíso",
        "Juan Aldama",
        "Tlaltenango de Sánchez Román",
        "Jerez de García Salinas",
        "Nochistlán de Mejía",
        "Monte Escobedo",
        "Tepechitlán"
      ],
      "Campeche": [
        "Campeche",
        "Ciudad del Carmen",
        "Champotón",
        "Escárcega",
        "Calkiní",
        "Dzitbalché",
        "Lerma",
        "Candelaria",
        "Hopelchén",
        "Bécal",
        "Hecelchakán",
        "Tenabo"
      ],
      "Colima": [
        "Manzanillo",
        "Colima",
        "Villa de Álvarez",
        "Tecomán",
        "Coquimatlán",
        "Cuauhtémoc",
        "Comala"
      ],
      "Tlaxcala": [
        "Apizaco",
        "Huamantla",
        "Zacatelco",
        "Contla",
        "Ocatlán",
        "Tlaxcala",
        "San Isidro Buen Suceso",
        "Santa Ana Chiautempan",
        "Ixtenco"
      ]
    },
    "Colombia": {
      "Bogotá": [
        "Bogotá"
      ],
      "Antioquia": [
        "Medellín",
        "Bello",
        "Itagüí",
        "Envigado",
        "Turbo",
        "Sabaneta",
        "Chigorodó",
        "Caldas",
        "Copacabana",
        "Necoclí",
        "Marinilla",
        "Carepa",
        "Girardota",
        "Barbosa",
        "Guarne",
        "El Bagre",
        "Puerto Berrío",
        "Carmen de Viboral",
        "Yarumal",
        "Andes",
        "Urrao",
        "Arboletes",
        "Cáceres",
        "Tarazá",
        "Segovia",
        "Santa Rosa de Osos",
        "Sonsón",
        "San Pedro de Urabá",
        "Remedios",
        "Amagá",
        "Ciudad Bolívar",
        "San Juan de Urabá",
        "Nechí",
        "Yolombó",
        "Valdivia",
        "Donmatías",
        "Dabeiba",
        "Santa Fe de Antioquia",
        "Amalfi",
        "Mutatá",
        "Puerto Triunfo",
        "Santa Bárbara",
        "Fredonia",
        "Concordia",
        "La Unión",
        "Salgar",
        "Betulia",
        "Retiro",
        "Anorí",
        "Támesis",
        "Sopetrán",
        "Titiribí",
        "Jardín",
        "Ebéjico",
        "Cisneros",
        "Santo Domingo",
        "Granada",
        "Guatapé",
        "Uramita",
        "Tarso",
        "Heliconia",
        "Caramanta"
      ],
      "Valle del Cauca": [
        "Cali",
        "Buenaventura",
        "Tuluá",
        "Cartago",
        "Guadalajara de Buga",
        "Jamundí",
        "Yumbo",
        "Florida",
        "Pradera",
        "Zarzal",
        "Roldanillo",
        "La Unión",
        "Dagua",
        "Guacarí",
        "Caicedonia",
        "Alcalá",
        "Ginebra",
        "Bugalagrande",
        "Obando",
        "El Águila"
      ],
      "Atlántico": [
        "Barranquilla",
        "Soledad",
        "Sabanalarga",
        "Baranoa",
        "Sabanagrande",
        "Luruaco",
        "Repelón",
        "Puerto Colombia",
        "Palmar de Varela",
        "Ponedera",
        "Polonuevo",
        "Campo de la Cruz",
        "Juan de Acosta",
        "Manatí",
        "Candelaria",
        "Santa Lucía",
        "Tubará",
        "Usiacurí"
      ],
      "Bolívar": [
        "Cartagena",
        "Magangué",
        "El Carmen de Bolívar",
        "Arjona",
        "Turbaco",
        "María la Baja",
        "Santa Rosa del Sur",
        "San Pablo",
        "San Juan Nepomuceno",
        "Pinillos",
        "Santa Rosa",
        "Achí",
        "Tiquisio",
        "San Jacinto",
        "Montecristo",
        "Simití",
        "Altos del Rosario",
        "San Jacinto del Cauca",
        "Hatillo de Loba",
        "Córdoba",
        "Talaigua Nuevo",
        "Regidor",
        "Margarita",
        "Norosí"
      ],
      "Cundinamarca": [
        "Soacha",
        "Zipaquirá",
        "Fusagasugá",
        "Girardot",
        "Facatativá",
        "Madrid",
        "Chía",
        "Mosquera",
        "Funza",
        "Cajicá",
        "Villeta",
        "Guaduas",
        "Villa de San Diego de Ubaté",
        "Sibaté",
        "Tocancipá",
        "Tabio",
        "Sopó",
        "La Calera",
        "Chocontá",
        "Cogua",
        "El Colegio",
        "Silvania",
        "Villapinzón",
        "Tenjo",
        "Tocaima",
        "El Rosal",
        "Suesca",
        "Guasca",
        "Anolaima",
        "Bojacá",
        "Agua de Dios",
        "Lenguazaque",
        "Medina",
        "Granada",
        "Junín",
        "San Francisco",
        "Paratebueno",
        "Guatavita",
        "Albán",
        "Zipacón"
      ],
      "Huila": [
        "Palermo",
        "Neiva",
        "Pitalito",
        "Garzón",
        "Acevedo",
        "Gigante",
        "Campoalegre",
        "San Agustín",
        "Aipe",
        "Isnos",
        "Algeciras",
        "Guadalupe",
        "Suaza",
        "Timaná"
      ],
      "Norte de Santander": [
        "Cúcuta",
        "Ocaña",
        "Los Patios",
        "Villa del Rosario",
        "Pamplona",
        "Tibú",
        "El Zulia",
        "Teorama",
        "Sardinata",
        "El Tarra"
      ],
      "Risaralda": [
        "Pereira",
        "Dosquebradas",
        "Santa Rosa de Cabal",
        "Quinchía",
        "La Virginia",
        "Belén de Umbría",
        "Marsella"
      ],
      "Santander": [
        "Bucaramanga",
        "Floridablanca",
        "Barrancabermeja",
        "Girón",
        "Piedecuesta",
        "San Gil",
        "Cimitarra",
        "San Vicente de Chucurí",
        "Puerto Wilches",
        "Barbosa",
        "Socorro",
        "Vélez",
        "Sabana de Torres",
        "Landázuri",
        "Los Santos",
        "Curití",
        "Oiba",
        "Puente Nacional",
        "El Playón",
        "Mogotes",
        "Charalá",
        "Suaita",
        "Zapatoca",
        "La Belleza",
        "Aratoca",
        "Puerto Parra",
        "Coromoro",
        "Simacota",
        "Tona",
        "Florián",
        "Villanueva"
      ],
      "Cesar": [
        "Valledupar",
        "Aguachica",
        "Agustín Codazzi",
        "Bosconia",
        "Chimichagua",
        "San Alberto",
        "Pueblo Bello",
        "El Paso",
        "Curumaní",
        "La Jagua de Ibirico",
        "Pailitas",
        "Pelaya",
        "Río de Oro"
      ],
      "Tolima": [
        "Ibagué",
        "Espinal",
        "Chaparral",
        "Líbano",
        "Melgar",
        "Guamo",
        "Planadas",
        "Fresno",
        "Purificación",
        "Flandes",
        "Coyaima",
        "Rioblanco",
        "Honda",
        "Ataco",
        "Natagaima",
        "Rovira",
        "Guayabal",
        "Alvarado",
        "Ambalema"
      ],
      "Meta": [
        "Villavicencio",
        "Acacías",
        "La Macarena",
        "Puerto López",
        "San Martín",
        "Vistahermosa",
        "Puerto Concordia",
        "Uribe"
      ],
      "Magdalena": [
        "Santa Marta",
        "Ciénaga",
        "Fundación",
        "Plato",
        "El Banco",
        "Aracataca",
        "Pivijay",
        "Puebloviejo",
        "El Difícil",
        "Sitionuevo",
        "Guamal",
        "Santa Ana",
        "Nueva Granada",
        "El Retén",
        "El Piñón",
        "Chivolo",
        "Tenerife",
        "Santa Bárbara de Pinto",
        "San Zenón",
        "Pedraza"
      ],
      "Córdoba": [
        "Montería",
        "Montelíbano",
        "Cereté",
        "Tierralta",
        "Planeta Rica",
        "Ciénaga de Oro",
        "Puerto Libertador",
        "Lorica",
        "Chinú",
        "San Andrés de Sotavento",
        "Ayapel",
        "Valencia",
        "San Pelayo",
        "Pueblo Nuevo",
        "Tuchín",
        "San Bernardo del Viento",
        "San Antero",
        "Puerto Escondido",
        "Moñitos",
        "San Carlos",
        "Los Córdobas",
        "Canalete",
        "Buenavista"
      ],
      "Caldas": [
        "Manizales",
        "La Dorada",
        "Riosucio",
        "Villamaría",
        "Neira",
        "Supía",
        "Pensilvania",
        "Manzanares",
        "Aguadas"
      ],
      "Nariño": [
        "Pasto",
        "Tumaco",
        "Ipiales",
        "Samaniego",
        "El Charco",
        "Cumbal",
        "Túquerres",
        "Bocas de Satinga",
        "Guachavés",
        "Buesaco",
        "Sandoná",
        "San José",
        "La Unión",
        "Taminango",
        "San Bernardo",
        "Sotomayor",
        "San Lorenzo",
        "Córdoba",
        "Iles"
      ],
      "Quindío": [
        "Armenia",
        "Montenegro",
        "Quimbaya",
        "Circasia",
        "Filandia",
        "Salento",
        "Córdoba"
      ],
      "Cauca": [
        "Popayán",
        "Santander de Quilichao",
        "La Vega",
        "Puerto Tejada",
        "Miranda",
        "Cajibío",
        "El Bordo",
        "Belalcázar",
        "Timbío",
        "Caldono",
        "Corinto",
        "Silvia",
        "Inzá",
        "Argelia",
        "Morales",
        "Balboa",
        "Timbiquí",
        "Almaguer",
        "Totoró",
        "López",
        "Guapí",
        "Rosas"
      ],
      "Sucre": [
        "Sincelejo",
        "Corozal",
        "San Marcos",
        "San Onofre",
        "Sampués",
        "Santiago de Tolú",
        "San Luis de Sincé",
        "Majagual",
        "San Benito Abad",
        "Galeras",
        "Ovejas",
        "Guaranda",
        "Caimito",
        "La Unión",
        "Coveñas",
        "Colosó"
      ],
      "Boyacá": [
        "Tunja",
        "Sogamoso",
        "Duitama",
        "Chiquinquirá",
        "Puerto Boyacá",
        "Paipa",
        "Moniquirá",
        "Samacá",
        "Villa de Leyva",
        "Aquitania",
        "Saboyá",
        "Chita",
        "Pesca",
        "Tibaná",
        "Muzo",
        "Motavita",
        "Santana",
        "Maripí",
        "Soatá",
        "Socha",
        "Boavita",
        "Güicán",
        "Cubará",
        "Tasco",
        "Sutamarchán",
        "Chitaraque",
        "Chíquiza",
        "Tota",
        "San José de Pare",
        "Arcabuco",
        "El Cocuy",
        "Labranzagrande"
      ],
      "Casanare": [
        "Yopal",
        "Aguazul",
        "Paz de Ariporo",
        "Villanueva",
        "Tauramena",
        "Orocué",
        "San Luis de Palenque"
      ],
      "La Guajira": [
        "Ríohacha",
        "Maicao",
        "Uribia",
        "Manaure",
        "San Juan del Cesar",
        "Dibulla",
        "Fonseca",
        "Hatonuevo",
        "Urumita"
      ],
      "Caquetá": [
        "Florencia",
        "San Vicente del Caguán",
        "Cartagena del Chairá",
        "Puerto Rico",
        "Solano",
        "La Montañita",
        "El Doncello",
        "El Paujíl"
      ],
      "Chocó": [
        "Quibdó",
        "Pie de Pató",
        "Riosucio",
        "Istmina",
        "Tadó",
        "Condoto",
        "El Carmen de Atrato",
        "Nuquí",
        "Juradó"
      ],
      "Arauca": [
        "Arauca",
        "Tame",
        "Arauquita",
        "Fortul"
      ],
      "San Andrés y Providencia": [
        "San Andrés"
      ],
      "Guaviare": [
        "San José del Guaviare",
        "El Retorno"
      ],
      "Amazonas": [
        "Leticia",
        "Puerto Nariño"
      ],
      "Vaupés": [
        "Mitú"
      ],
      "Putumayo": [
        "Mocoa",
        "Puerto Asís",
        "Orito",
        "Valle del Guamuez",
        "La Dorada",
        "Puerto Guzmán",
        "Villagarzón"
      ],
      "Guainía": [
        "Inírida"
      ],
      "Vichada": [
        "Puerto Carreño",
        "Cumaribo",
        "La Primavera"
      ]

    },
    ...data
  }
}


// var ctslst = {};
//     var ctgry = dta.reduce(function (r, a) {
//       r[a.country] = r[a.country] || [];
//       r[a.country].push(a);
//       return r;
//     }, Object.create(null));

//     for (const [key] of Object.entries(ctgry)) {
//       ctslst[key] = {};
//       var states = ctgry[key];
//       //console.log(states);
//       var sts = states.reduce(function (r, a) {
//         r[a.state] = r[a.state] || [];
//         r[a.state].push(a);
//         return r;
//       }, Object.create(null));

//       for (const [key2] of Object.entries(sts)) {
//         ctslst[key][key2] = [];
//         var ctis = sts[key2];
//         var cts = ctis.reduce(function (r, a) {
//           r[a.city] = r[a.city] || [];
//           r[a.city].push(a);
//           return r;
//         }, Object.create(null));
//         for (const [key3] of Object.entries(cts)) {
//           //console.log(key3);
//           ctslst[key][key2].push(key3);
//         }

//         //console.log(key, sts[key2]);
//       }
//     }
//     console.log("===", ctslst);